<template>
    <section id="popular-pages">
      <card icon="eye"
            :headline="$t('visitors.topPages.headline')"
            :description="$t('visitors.topPages.description')"
      >

      </card>
    </section>
</template>

<script>
    export default {
        name: "PopularPages"
    }
</script>

<style scoped lang="scss">

</style>
